<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.USERS') }}
    </p>
    <section class="content-margin">
      <DRFilter @changeBusiness="changeBusiness"></DRFilter>
    </section>

    <div class="px15 m-t-40">
      <section class="table-content-part">
        <div class="d-flex justify-content-between">
          <div class="search-bar">
            <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
            <v-text-field
              v-model="search"
              single-line
              hide-details
              class="search-form"
            ></v-text-field>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="userList"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          class="cus-table editable-table main-table striped-table"
          item-key="id"
        >
          <!-- <template v-slot:[`item.owner`]="{ item }">
            <div class="d-flex justify-content-center">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.owner"></v-checkbox>
            </div>
          </template> -->
          <template v-slot:[`item.uname`]="{ item }">
            <div class="d-flex justify-content-center">
              {{item.first_name}} {{item.last_name}}
            </div>
          </template>
          <!-- <template v-slot:[`item.secure`]="{ item }">
            <div class="d-flex justify-content-center">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.secure"></v-checkbox>
            </div>
          </template> -->
          <template v-slot:[`item.freeze`]="{ item }">
            <div class="d-flex justify-content-center" v-if="item.status==2">
              <button class="primary-btn" @click="cancelFreezing(item.id)">
                Cancel Freezing
              </button>
            </div>
            <div class="d-flex justify-content-center" v-else-if="item.status!=2">
              <button class="primary-btn" @click="frozen(item.id)">
                {{ $t("BUSINESS_DETAILS.FREEZE") }}
              </button>
            </div>
          </template>
          <!-- <template v-slot:[`item.expanded-item`]="{ item }">
            <div @click="editItem(item)"  class="d-flex justify-content-center pointer" v-if="item.status==1">
              <img src="/assets/img/icons/pencil-light.svg" alt="">
              <td :colspan="headers.length">
                <v-container>
                </v-container>
                </td>
            </div>
          </template> -->
          <template v-slot:[`item.edit_user`]="{ item }">
            <div v-if="item.status!=2">
              <div class="d-flex justify-content-center align-items-center">
                <!-- <div class="expand-btn plus pointer" @click="expanded = [item]" v-if="!expanded.includes(item)"> -->
                <div class="expand-btn plus pointer" @click="toggleExpandChannel(1, item)" v-if="!expanded.includes(item)">
                  <img src="/assets/img/icons/pencil-light.svg" alt="">
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <!-- <div class="expand-btn minus pointer" @click="expanded = []" v-if="expanded.includes(item)"> -->
                <div class="expand-btn minus pointer" @click="toggleExpandChannel(1, [])" v-if="expanded.includes(item)">
                  <img src="/assets/img/icons/pencil-light.svg" alt="">
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.edit_secure`]="{ item }">
            <div v-if="item.status!=2">
              <div class="d-flex justify-content-center pointer" @click="toggleExpandChannel(2, item)" v-if="!expanded.includes(item)">
                <img src="/assets/img/icons/Layer 2.svg" alt="">
              </div>
              <div class="d-flex justify-content-center pointer" @click="toggleExpandChannel(2, [])" v-if="expanded.includes(item)">
                <img src="/assets/img/icons/Layer 2.svg" alt="">
              </div>
            </div>
          </template>
          <template v-slot:[`item.print`]="{ item }">
            <div class="d-flex justify-content-center pointer" @click="openLoginHistoryDialog(item)">
              <img src="/assets/img/icons/file-chart-line-light.svg" alt="">
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container v-if="expandId==1">
                <v-form v-model="isExpand1Valid">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="5"
                      md="3"
                    >
                      <v-text-field
                        v-model="item.first_name"
                        :label="$t('COMMON.FIRST_NAME') + '*'"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                      md="3"
                    >
                      <v-text-field
                        v-model="item.last_name"
                        :label="$t('COMMON.LAST_NAME') + '*'"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                      md="3"
                    >
                      <v-text-field
                        v-model="item.israeli_id"
                        :rules="[isValidIsraeliID]"
                        :label="$t('COMMON.ID')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                      md="3"
                    >
                      <v-text-field
                        v-model="item.phone_number"
                        :label="$t('TOURISM_PROFIT_PERCENTAGES.MOBILE_PHONE') + '*'"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      md="6"
                    >
                      <v-text-field
                        v-model="item.address"
                        :label="$t('COMMON.ADDRESS')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      md="6"
                    >
                      <v-text-field
                        v-model="item.account_mail"
                        :label="$t('COMMON.E_MAIL')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-text-field
                        v-model="item.username"
                        :label="$t('LOGIN.USERNAME') + '*'"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-select
                        v-model="permissions"
                        :items="rolesList"
                        :label="$t('MANAGEMENT.PERMISSIONS') + '*'"
                        item-text="name"
                        item-value="id"
                        return-object
                      ></v-select>
                    </v-col>
                    <v-btn
                      color="primary"
                      text
                      @click="resetPassDialog.showDialog=true"
                    >
                      {{ $t('MANAGEMENT.PASSWORD_RESET') }}
                    </v-btn>
                    <v-btn
                      color="error"
                      text
                      @click="chPassDialog.showDialog=true"
                    >
                      {{ $t('MANAGEMENT.CHANGE_PASSWORD') }}
                    </v-btn>
                  </v-row>
                </v-form>
                <v-btn
                  color="primary"
                  @click="editUserData(item)"
                  :disabled="!isExpand1Valid"
                >
                  {{ $t('COMMON.SAVE') }}
                </v-btn>
                <v-btn
                  color="error"
                  @click="toggleExpandChannel(2, [])"
                  class="mx20"
                >
                  {{ $t('SUPPLIER_PAYMENT.CANCEL') }}
                </v-btn>
                <v-dialog
                  v-model="chPassDialog.showDialog"
                  max-width="500px"
                  content-class="bg-white"
                >
                  <v-card-title>
                    <span class="text-h5">{{ $t("MANAGEMENT.CHANGE_PASSWORD") }}</span>
                  </v-card-title>
                  <v-container>
                    <v-card-text>
                      {{ $t("MANAGEMENT.CHANGE_USER_PASSWORD_TXT", {UNAME: item.username, BNAME: item.first_name + item.last_name }) }}
                    </v-card-text>
                    <v-form v-model="chPassDialog.isChPassFormValid">
                      <v-row class="px-7">
                        <v-col
                          cols="12"
                          sm="6"
                          class="py-0"
                        >
                          <v-text-field            
                            v-model="chPassDialog.password"
                            :append-icon="chPassDialog.show1 ? 'visibility' : 'visibility_off'"
                            :rules="[chPassDialog.rules.required, chPassDialog.rules.min]"
                            :type="chPassDialog.show1 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Password"
                            @click:append="chPassDialog.show1 = !chPassDialog.show1"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="px-7">
                        <v-col
                          cols="12"
                          sm="6"
                          class="py-0"
                        >
                          <v-text-field            
                            v-model="chPassDialog.rePassword"
                            :append-icon="chPassDialog.show2 ? 'visibility' : 'visibility_off'"
                            :rules="[chPassDialog.rules.required, chPassDialog.rules.min, passwordConfirmationRule]"
                            :type="chPassDialog.show2 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Re-enter Password"
                            @click:append="chPassDialog.show2 = !chPassDialog.show2"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-card-actions class="pt-8">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="changePassword(item.id)"
                        :disabled="!chPassDialog.isChPassFormValid"
                      >
                        {{ $t('GIFTCARD_PRODUCT.APPROVAL') }}
                      </v-btn>
                      <v-btn
                        color="error"
                        @click="closeChangePassDialog"
                      >
                        {{ $t('SUPPLIER_PAYMENT.CANCELATION') }}
                      </v-btn>
                    </v-card-actions>
                    <v-card-text>
                      {{ $t("MANAGEMENT.CHANGE_PASSWORD_DESC", {MNUM: item.phone_number}) }}
                    </v-card-text>
                  </v-container>
                </v-dialog>
                <v-dialog
                  v-model="resetPassDialog.showDialog"
                  max-width="500px"
                  content-class="bg-white"
                >
                  <v-card-title>
                    <span class="text-h5">{{ $t("MANAGEMENT.PASSWORD_RESET") }}</span>
                  </v-card-title>
                  <v-container>
                    <v-card-text>
                      {{ $t("MANAGEMENT.RESET_PASSWORD_DESC", {UNAME: item.username, BNAME: item.first_name + item.last_name, MNUM: item.phone_number }) }}
                    </v-card-text>
                    <v-card-actions class="pt-8">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="close"
                      >
                        {{ $t('GIFTCARD_PRODUCT.APPROVAL') }}
                      </v-btn>
                      <v-btn
                        color="error"
                        @click="resetPassDialog.showDialog=false"
                      >
                        {{ $t('SUPPLIER_PAYMENT.CANCELATION') }}
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-dialog>
              </v-container>
              <v-container v-if="expandId==2">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    xs="12"
                  >
                    <v-card
                      elevation="2"
                      class="p10"
                    >
                      <label for="">{{ $t('MANAGEMENT.SUCURE_IP') }}</label>
                      <!-- <v-checkbox
                        v-if="item.num_ip_allowed != null"
                        input-value="true"
                        value
                        :label="$t('MANAGEMENT.SUCURE_IP')"
                        @change="changeIpAllow($event, item.id)"
                      ></v-checkbox>
                      <v-checkbox
                        v-else
                        value
                        :label="$t('MANAGEMENT.IP_NUMBER_ADDRESSES')"
                       @change="changeIpAllow($event, item.id)"
                      ></v-checkbox> -->
                      <div class="d-flex justify-space-between align-baseline">
                        <v-text-field
                          :label="$t('MANAGEMENT.IP_NUMBER_ADDRESSES') + '*'"
                          type="number"
                          class="secure-form-input"
                          v-model="item.num_ip_allowed"
                        ></v-text-field>
                        <div class="d-flex">
                          <v-btn
                            color="primary"
                            @click="activeSessionIp(item.id, item.num_ip_allowed)"
                          >
                            {{ $t('COMMON.SAVE') }}
                          </v-btn>
                          <v-btn
                            color="error"
                            @click="cancelActiveIp(item.id)"
                            class="mx10"
                          >
                            {{ $t('SUPPLIER_PAYMENT.CANCEL') }}
                          </v-btn>
                        </div>
                      </div>
                      <v-data-table
                        :headers="secureForm.ipHeader"
                        :items="activitySessions.ips"
                        :items-per-page="5"
                        class="elevation-1"
                        v-if="item.num_ip_allowed"
                      >
                        <template v-slot:[`item.ip`]="{ item }">
                          <div class="d-flex justify-content-center pointer">
                            {{ item.ip_address }}
                          </div>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                          <div class="d-flex justify-content-center pointer">
                            <v-btn
                              color="error"
                              @click="removeIp(item.id)"
                              class="mx10"
                            >
                              {{ $t('MANAGEMENT.DELETION') }}
                            </v-btn>
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    xs="12"
                  >
                    <v-card
                      elevation="2"
                      class="p10"
                    >
                      <label for="">{{ $t('COMMON.SECURE_DEVICE') }}</label>
                      <!-- <v-checkbox
                        :v-model="item.num_device_allowed ? true : false"
                        :label="$t('COMMON.SECURE_DEVICE')"
                      ></v-checkbox> -->
                      <div class="d-flex justify-space-between align-baseline">
                        <v-text-field
                          :label="$t('MANAGEMENT.SEVERAL_SECURE_DEVICES')"
                          type="number"
                          class="secure-form-input"
                          v-model="item.num_device_allowed"
                        ></v-text-field>
                        <div class="d-flex">
                          <v-btn
                            color="primary"
                            @click="activeSessionToken(item.id, item.num_device_allowed)"
                          >
                            {{ $t('COMMON.SAVE') }}
                          </v-btn>
                          <v-btn
                            color="error"
                            class="mx10"
                            @click="cancelActiveToken(item.id)"
                          >
                            {{ $t('SUPPLIER_PAYMENT.CANCEL') }}
                          </v-btn>
                        </div>
                      </div>
                      <v-data-table
                        :headers="secureForm.tokenHeader"
                        :items="activitySessions.tokens"
                        :items-per-page="5"
                        class="elevation-1"
                        v-if="item.num_device_allowed"
                      >
                        <template v-slot:[`item.action`]="{ item }">
                          <div class="d-flex justify-content-center pointer">
                            <v-btn
                              color="error"
                              @click="removeToken(item.id)"
                              class="mx10"
                            >
                              {{ $t('MANAGEMENT.DELETION') }}
                            </v-btn>
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>  
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </section>
    </div>
    <div class="content-margin px15 m-b-40">
      <button class="primary-btn btn-limit-width bg-yellow m-t-40" @click="openNewUserModal">
        {{ $t("MANAGEMENT.ADD_USER") }}
      </button>
    </div>
    <v-dialog
      v-model="loginHistoryDialog.showDialog"
      max-width="700px"
      content-class="p-relative bg-white login-history-dialog"
    >
      <v-btn
        icon
        class="dialog-close-btn"
        @click="loginHistoryDialog.showDialog=false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-center">
        <span class="text-h5">{{ $t("MANAGEMENT.LOGIN_HISTORY") }} ( {{loginHistoryDialog.userName}} )</span>
      </v-card-title>
      <v-container>
        <section class="table-content-part m-t-10">
          <div class="d-flex justify-content-between">
            <div class="search-bar">
              <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
              <v-text-field
                v-model="loginHistoryDialog.search"
                single-line
                hide-details
                class="search-form"
              ></v-text-field>
            </div>
          </div>
          <v-data-table
            :headers="loginHistoryDialog.headers"
            :search="loginHistoryDialog.search"
            :items="loginHistoryDialog.historyData"
            class="cus-table editable-table main-table striped-table"
            item-key="id"
          >
            <template v-slot:[`item.id`]="{ }">
              <div class="d-flex justify-content-center align-items-center">
                {{ loginHistoryDialog.id }}
              </div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <div class="d-flex justify-content-center align-items-center">
                {{ item.timestamp_login | formatDate }}
              </div>
            </template>
            <template v-slot:[`item.day`]="{ item }">
              <div class="d-flex justify-content-center align-items-center">
                {{ item.timestamp_login | formatTime }}
              </div>
            </template>
            <template v-slot:[`item.time`]="{ item }">
              <div class="d-flex justify-content-center align-items-center">
                {{ getDayFromTimeStamp(item.timestamp_login) }}
              </div>
            </template>
          </v-data-table>
        </section>
      </v-container>
    </v-dialog>
    <ConfirmModal @confirmData="confirmDeleteIp">
      <template v-slot:modal-content>
        After deleting, previous data will be lost. Do you want to continue?
      </template>
    </ConfirmModal>
    <NewUserModal :business_id="businessID" @userCreated="updateUserList"></NewUserModal>
    
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main> 
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DRFilter from "../../../components/management/DRFilter.vue"
import ConfirmModal from "../../../components/management/ConfirmModal.vue"
import NewUserModal from "../../../components/management/NewUserModal.vue"

export default {
  name: 'DRUsers',
  components: {
    DRFilter,
    ConfirmModal,
    NewUserModal
  },
  data() {
    return {
      openFields: false,
      search: '',
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: this.$t('MANAGEMENT.NUMBER'),
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: this.$t('MANAGEMENT.USERNAME'),
          align: 'center',
          sortable: false,
          value: 'username',
        },
        {
          text: this.$t('MANAGEMENT.CELLPHONE_NUMBER'),
          align: 'center',
          sortable: false,
          value: 'phone_number',
        },
        {
          text: this.$t('COMMON.E_MAIL'),
          align: 'center',
          sortable: false,
          value: 'account_mail',
        },
        {
          text: this.$t('LOGIN.USERNAME'),
          align: 'center',
          sortable: false,
          value: 'uname',
        },
        // {
        //   text: this.$t('MANAGEMENT.OWNER'),
        //   align: 'center',
        //   sortable: false,
        //   value: 'owner',
        // },
        // {
        //   text: this.$t('COMMON.SECURE'),
        //   align: 'center',
        //   sortable: false,
        //   value: 'secure',
        // },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'freeze',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'edit_user',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'edit_secure',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'print',
        },
      ],
      editedItem: {
        first_name: '',
        last_name: '',
        id: '',
        phone_number: '',
        address: '',
        email: '', 
        user_name: '',
        authorized: '',
      },
      secureForm: {
        isIPChk: true,
        ipNumber: 0,
        ipHeader: [
          {
            text: this.$t('REPORT_SALES.IP'),
            align: 'center',
            sortable: false,
            value: 'ip',
          },
          {
            text: this.$t('COMMON.ACTION'),
            align: 'center',
            sortable: false,
            value: 'action',
          },
        ],
        tokenHeader: [
          {
            text: this.$t('REPORT_SALES.IP'),
            align: 'center',
            sortable: false,
            value: 'token',
          },
          {
            text: this.$t('COMMON.ACTION'),
            align: 'center',
            sortable: false,
            value: 'action',
          },
        ],
        ipToRemove: '',
        tokenToRemove: '',
        expandItemId: ''
      },
      businessID: '',
      distributeID: '',
      dialog: false,
      expandId: 1,
      permissions: { name: '', id: '' },
      chPassDialog: {
        showDialog: false,
        password: '',
        rePassword: '',
        rules: {
          required: v => !!v || "Password is required",
          min: v => (v && v.length >= 4) || "minimum 4 characters"
        },
        show1: false,
        show2: false,
        isChPassFormValid: false
      },
      resetPassDialog: {
        showDialog: false
      },
      loginHistoryDialog: {
        showDialog: false,
        search: '',
        headers: [
          {
            text: this.$t('SUPPLIER_PAYMENT.ID_NUMBER'),
            align: 'center',
            sortable: false,
            value: 'id',
          },
          {
            text: this.$t('REPORT_SALES.IP'),
            align: 'center',
            sortable: false,
            value: 'user_ip',
          },
          {
            text: this.$t('COMMON.DATE'),
            align: 'center',
            sortable: false,
            value: 'date',
          },
          {
            text: this.$t('COMMON.DAY'),
            align: 'center',
            sortable: false,
            value: 'day',
          },
          {
            text: this.$t('COMMON.TIME'),
            align: 'center',
            sortable: false,
            value: 'time',
          }
        ],
        historyData: '',
        userName: '',
        id: ''
      },
      isExpand1Valid: false,
      isIpDel: false
    }
  },
  computed: {
    ...mapState({
      userList: (state) => state.seller_tourism_percentage.userList,
      rolesList: (state) => state.seller_tourism_percentage.rolesList,
      activitySessions: (state) => state.seller_tourism_percentage.activitySessions,
      loading1: (state) => state.seller_tourism_percentage.loading,
      loading2: (state) => state.shaniv_management.loading,
    }),
    passwordConfirmationRule() {
      return () => (this.chPassDialog.password == this.chPassDialog.rePassword) || 'Password must match'
    },
    loading() {
      return this.loading1 || this.loading2;
    }
  },
  async created() {
    if (this.rolesList.length == []) {
      await this.getRoles();
    }
    // this.rolesList.forEach(el => {
    //   this.permissions.options.push({
    //     label: el.name,
    //     code: el.id
    //   })
    // });
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getUserList: 'getUserList',
      getRoles: 'getRoles',
      activateUser: 'activateUser',
      freezeUser: 'freezeUser',
      updateUserPassword: 'updateUserPassword',
      updateUserData: 'updateUserData',
      getActivitySession: 'getActivitySession',
      deleteActivitySessionIp: 'deleteActivitySessionIp',
      deleteActivitySessionToken: 'deleteActivitySessionToken',
      cancelActivitySessionIp: 'cancelActivitySessionIp',
      cancelActivitySessionToken: 'cancelActivitySessionToken',
      activateSessionIp: 'activateSessionIp',
      activateSessionToken: 'activateSessionToken',
    }),
    isValidIsraeliID(id) {
      var id = String(id).trim();
      if (id.length == 0 || id == 'null') {
        return true;
      } else if (id.length > 9 || id.length < 5) {
        return false;
      }
      // Pad string with zeros up to 9 digits
      id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

      return Array.from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    },
    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    save() {

    },
    close() {

    },
    async changeBusiness(data) {
      this.businessID = data.business_id;
      this.distributeID = data.distribute_id;
      await this.getUserList({
        distributor: data.distribute_id,
        business: data.business_id,
      });

      if (this.userList && this.userList.length) {
        this.userList.forEach(el => {
          let address = el.country + ' ' + el.city + ' ' + el.street;
          el.address = address;
        });
      }
      // this.tableData = this.businessCommissionData;
      // await this.getSuppliers({id: id});
      // let suppliers_list = [];
      // this.suppliersData.forEach(item => {
      //   suppliers_list.push({
      //     code: item.supplier_id,
      //     label: item.supplier_name
      //   })
      // });
      // this.suppliersList.options = suppliers_list;
      // this.suppliersList.selected = "";
    },
    toggleExpandChannel(id, item) {
      this.expandId = id;
      this.expanded = [item];
      if (id == 1 && item.length != 0) {
        this.permissions = item.role;
      }
      if (id == 2 && item.length != 0) {
        this.getActivitySession({id: item.id});
        this.secureForm.expandItemId = item.id;
      }
    },
    closeChangePassDialog() {
      this.chPassDialog.password = "";
      this.chPassDialog.rePassword = "";
      this.chPassDialog.showDialog = false;
    },
    async cancelFreezing(id) {
      await this.activateUser(id);
      this.changeBusiness({
        business_id: this.businessID,
        distribute_id: this.distributeID
      })
    },
    async frozen(id) {
      await this.freezeUser(id);
      this.changeBusiness({
        business_id: this.businessID,
        distribute_id: this.distributeID
      })
    },
    async changePassword(id) {
      this.updateUserPassword({
        current_password: true, 
        user_id: id,
        new_password: this.chPassDialog.rePassword
      });
      this.closeChangePassDialog();
    },
    openLoginHistoryDialog(item) {
      this.loginHistoryDialog.historyData = item.login_history;
      this.loginHistoryDialog.id = item.id;
      this.loginHistoryDialog.userName = item.first_name + " " + item.last_name;
      this.loginHistoryDialog.showDialog = true;
    },
    async editUserData(item) {
      const params = {
        first_name: item.first_name,
        last_name: item.last_name,
        israeli_id: item.israeli_id,
        phone_number: item.phone_number,
        account_mail: item.account_mail,
        username: item.username,
        role_id: this.permissions.id,
      }
      await this.updateUserData({
        id: item.id,
        params: params
      })
      this.changeBusiness({
        business_id: this.businessID,
        distribute_id: this.distributeID
      })
    },
    removeIp(id) {
      this.secureForm.ipToRemove = id;
      this.isIpDel = true;
      this.$modal.show('confirmModal');
    },
    removeToken(id) {
      this.secureForm.tokenToRemove = id;
      this.isIpDel = false;
      this.$modal.show('confirmModal');
    },
    async confirmDeleteIp() {
      this.$modal.hide('confirmModal');
      if (this.isIpDel) {
        await this.deleteActivitySessionIp({
          id: this.secureForm.ipToRemove
        })
      } else {
        await this.deleteActivitySessionToken({
          id: this.secureForm.tokenToRemove
        })
      }
      this.getActivitySession({id: this.secureForm.expandItemId})
    },
    openNewUserModal() {
      this.$modal.show('newUserModal');
    },
    async cancelActiveIp(id) {
      await this.cancelActivitySessionIp({
        "user_id": id
      });
      this.updateUserList();
    },
    async cancelActiveToken(id) {
      await this.cancelActivitySessionToken({
        "user_id": id
      });
      this.updateUserList();
    },
    async activeSessionIp(id, num) {
      await this.activateSessionIp({
        "num_ip_allowed": num,
        "user_id": id
      });
      this.updateUserList();
    },
    async activeSessionToken(id, num) {
      await this.activateSessionToken({
        "num_device_allowed": num,
        "user_id": id
      });
      this.updateUserList();
    },
    updateUserList() {
      this.getUserList({
        distributor: this.distributeID,
        business: this.businessID,
      });
      this.toggleExpandChannel(2, []);
    }
    // changeIpAllow(val, id) {
    //   console.log("----", e);
    //   console.log("++++", id);
    //   let tempUser = this.userList.find(el => {
    //     return el.id == id;
    //   });
    //   if (val) {
    //   }
    // }
  }
}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
label {
  height: 27px;
  display: block;
}

.secure-form-input {
  max-width: 150px;
}

.login-history-dialog {
  .table-content-part {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .form-item {
    width: 100%;
  }
}

@media screen and (max-width: 635px) {
  .primary-btn {
    height: unset;
    min-height: 44px;
  }
}
</style>